// 引入 vue
import Vue from 'vue'
//引入 vuex
import Vuex from 'vuex'
//安装vuex插件
Vue.use(Vuex)

// 创建vuex实例
const store = new Vuex.Store({
    state: {
        companyList: [], // 公司列表
        messageNum: '',
        buyLocalDialogVisible: 0, //订购弹窗弹窗与否
        darenBuyDialog: false,
        kuaishouBuy: false,
        xhsBuy: false,
        zbBuy: false,
        roomDialog: false,
        wxDialog: false,
        tbDialog: false
    },
    mutations: {
        setCompanyList(state, val) {
            state.companyList = val
        },
        setMessageNum(state, val) {
            state.messageNum = val
        },
        setBuyLocalDialogVisible(state, val) {
            state.buyLocalDialogVisible = val
        },
        setDarenDialogVisible(state, val) {
            state.darenBuyDialog = val
        },
        setKuaiShouBuy(state, val) {
            state.kuaishouBuy = val
        },
        setRoomDialog(state, val) {
            state.roomDialog = val
        },
        setWxDialog(state, val) {
            state.wxDialog = val
        },
        setTbDialog(state, val) {
            state.tbDialog = val
        },
        setXhsBuy(state, val) {
            state.xhsBuy = val
        },
        setZbBuy(state, val) {
            state.zbBuy = val
        },
    },
    actions: {

    },
    getters: {
        companyList(state) {
            return state.companyList
        },
        // 当前选中公司
        companySelected(state) {
            let { companyList } = state
            let company = companyList.filter(
                item => item.companyCode === +localStorage.getItem('companyCode')
            )
            return company.length > 0 ? company[0] : {}
        },
        messageNum(state) {
            return state.messageNum
        },
    },
    modules: {

    }
})

//导出store对象
export default store;